export const ROBOT_TYPES = [
  { label: "Wave 300", value: "wave_300" },
  { label: "Wave 200", value: "wave_200" },
  { label: "Wave 150", value: "wave_150" },
  { label: "2x2", value: "2x2" },
  { label: "Wave 100", value: "wave_100" },
  { label: "Wave 75", value: "wave_75" },
  { label: "Wave 20", value: "wave_20" },
  { label: "Hammer Head", value: "hammer_head" },
  { label: "Swash", value: "swash" },
  { label: "Liberty", value: "liberty" },
  { label: "HH21", value: "hh21" },
  { label: "HH30", value: "hh30" },
  { label: "Other", value: "other" }
];

export const CONDITION = [
  { label: "Good", value: "good" },
  { label: "Average", value: "average" },
  { label: "Poor", value: "poor" },
  { label: "Action Recommended", value: "replacement_to_be_quoted" }
];
export const URGENCY = [
  { label: "Urgent", value: "urgent" },
  { label: "Up to 6 months", value: "up_to_6_months" },
  { label: "Up to 12 months", value: "up_to_12_months" },
  { label: "Up to 24 months", value: "up_to_24_months" },
  { label: "Not urgent", value: "not_urgent" }
];

export const MOUNT = [
  { label: "Under Bench", value: "under_bench" },
  { label: "Reel", value: "reel" },
  { label: "Pop-Up", value: "pop_up" },
  { label: "Wall", value: "wall" },
  { label: "Other", value: "other" }
];

export const POOL_COVER_BRANDS = [
  { label: "ABGAL", value: "abgal" },
  { label: "Coombes", value: "coombes" },
  { label: "Sunbather", value: "sunbather" },
  { label: "Daisy", value: "daisy" },
  { label: "Other", value: "other" }
];

export const LANE_ROPES_LENGTH = [
  { label: "50m", value: "50m" },
  { label: "25m", value: "25m" },
  { label: "Custom", value: "custom" }
];

export const DISC_SIZE = [
  { label: "150mm", value: "150mm" },
  { label: "125mm", value: "125mm" },
  { label: "110mm", value: "110mm" },
  { label: "65mm", value: "65mm" }
];

export const LANE_ROPES_BRANDS = [
  { label: "SR Smith", value: "sr_smith" },
  { label: "Swimplex", value: "swimplex" },
  { label: "Other", value: "other" }
];

export const YES_NO = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" }
];

export const POOLIES_PAL_BRANDS = [
  { label: "Sunbather", value: "sunbather" },
  { label: "Orimatech", value: "orimatech" },
  { label: "Other", value: "other" }
];

export const DISABILITY_HOIST_BRANDS = [
  { label: "Para Mobility", value: "para_mobility" },
  { label: "SR Smith", value: "sr_smith" },
  { label: "Other", value: "other" }
];

export const CORNER_OPTIONS = [
  { label: "External", value: "external" },
  { label: "Internal", value: "internal" }
];

export const COMMERCIAL_USERS = [
  "josh.darragh@maytronics.com",
  "steven.bricknell@maytronics.com",
  "michael.mckenzie@maytronics.com"
];

export const COMMERCIAL_CSV_FIELDS = {
  disability_hoist: {
    brand: "DISABILITY HOIST BRAND",
    comment: "DISABILITY HOIST COMMENT",
    condition: "DISABILITY HOIST CONDITION",
    location: "DISABILITY HOIST LOCATION",
    disability_hoist_image: "DISABILITY HOIST IMAGE"
  },
  dolphin_lift: {
    socket_size: "DOLPHIN LIFT SOCKET SIZE",
    comment: "DOLPHIN LIFT COMMENT",
    condition: "DOLPHIN LIFT CONDITION",
    dolphin_lift_image: "DOLPHIN LIFT IMAGE"
  },
  external_corner: {
    location: "EXTERNAL CORNER LOCATION",
    condition: "EXTERNAL CORNER CONDITION",
    rebate: "EXTERNAL CORNER REBATE",
    quantity: "EXTERNAL CORNER QUANTITY",
    rigid: "EXTERNAL CORNER RIGID",
    flex: "EXTERNAL CORNER FLEX",
    size: "EXTERNAL CORNER SIZE",
    cross_fall: "EXTERNAL CORNER CROSS FALL",
    comment: "EXTERNAL CORNER COMMENT"
  },
  internal_corner: {
    location: "INTERNAL CORNER LOCATION",
    condition: "INTERNAL CORNER CONDITION",
    rebate: "INTERNAL CORNER REBATE",
    quantity: "INTERNAL CORNER QUANTITY",
    rigid: "INTERNAL CORNER RIGID",
    flex: "INTERNAL CORNER FLEX",
    size: "INTERNAL CORNER SIZE",
    cross_fall: "INTERNAL CORNER CROSS FALL",
    comment: "INTERNAL CORNER COMMENT"
  },
  lane_ropes: {
    location: "LANE ROPES LOCATION",
    condition: "LANE ROPES CONDITION",
    quantity: "LANE ROPES QUANTITY",
    length: "LANE ROPES LENGTH",
    disc_size: "LANE ROPES DISC SIZE",
    reels: "LANE ROPES REELS",
    brand: "LANE ROPES BRAND",
    comment: "LANE ROPES COMMENT",
    lane_ropes_image: "LANE ROPES IMAGE"
  },
  pool_covers: {
    location: "POOL COVERS LOCATION",
    condition: "POOL COVERS CONDITION",
    quantity: "POOL COVERS QUANTITY",
    length: "POOL COVERS LENGTH",
    width: "POOL COVERS WIDTH",
    mount: "POOL COVERS MOUNT",
    brand: "POOL COVERS BRAND",
    comment: "POOL COVERS COMMENT",
    pool_covers_image: "POOL COVERS IMAGE"
  },
  robot_type: {
    robot_type: "ROBOT TYPE",
    robot_type_location: "ROBOT TYPE LOCATION",
    robot_type_condition: "ROBOT TYPE CONDITION",
    robot_type_comment: "ROBOT TYPE COMMENT",
    robot_type_serial: "ROBOT TYPE SERIAL",
    robot_type_image: "ROBOT TYPE IMAGE"
  },
  poolies_pal: {
    condition: "POOLIES PAL CONDITION",
    brand: "POOLIES PAL BRAND",
    comment: "POOLIES PAL COMMENT",
    poolies_pal_image: "POOLIES PAL IMAGE"
  },
  starting_blocks: {
    quantity: "STARTING BLOCKS QUANTITY",
    condition: "STARTING BLOCKS CONDITION",
    cover: "STARTING BLOCKS COVER",
    brand: "STARTING BLOCKS BRAND",
    comment: "STARTING BLOCKS COMMENT",
    location: "STARTING BLOCKS LOCATION",
    starting_blocks_image: "STARTING BLOCKS IMAGE"
  },
  wet_deck: {
    location: "WET DECK LOCATION",
    condition: "WET DECK CONDITION",
    rebate: "WET DECK REBATE",
    rigid: "WET DECK RIGID",
    flex: "WET DECK FLEX",
    rigid_size: "WET DECK  RIGID SIZE",
    rigid_cross_fall: "WET DECK RIGID CROSS FALL",
    comment: "WET DECK COMMENT",
    rigid_linear: "WET DECK RIGID LINEAR",
    flex_size: "WET DECK FLEX SIZE",
    flex_cross_fall: "WET DECK FLEX CROSS FALL",
    flex_linear: "WET DECK FLEX LINEAR",
    internal_corner: "WET DECK INTERNAL CORNER",
    external_corner: "WET DECK EXTERNAL CORNER",
    wet_deck_image: "WET DECK IMAGE"
  }
};

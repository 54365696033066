export default {
  last_month: {
    revenue: [],
    month: 0,
    year: 0
  },
  last_quarter: {
    revenue: [],
    quarter: 0,
    year: 0
  },
  yearly: {
    revenue: [],
    pickedYear: 0,
    year: 0
  }
};

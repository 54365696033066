export default {
  initialValidation: { success: false, error: "" },
  error: "",
  registrations: [],
  loading: false,
  filters: [],
  count: 0,
  toDelete: "",
  toEdit: "",
  toRegister: ""
};

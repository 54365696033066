export default {
  getMonthlyRevenue(state) {
    return state.last_month;
  },
  getQuarterlyRevenue(state) {
    return state.last_quarter;
  },
  getYearlyRevenue(state) {
    return state.yearly;
  }
};

import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_LOGS](state, data) {
    state.logs = data.logs;
    state.count = data.count;
  },
  [Mutations.SET_LOG_LOADING](state, loading) {
    state.logsLoading = loading;
  }
}
import axios from "axios";
import { getToken } from "./firebase";
export const SALES_APP_API = axios.create({
  baseURL: process.env.VUE_APP_SALES_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
SALES_APP_API.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${process.env.VUE_APP_SALES_APP_API_KEY} ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Users
export const getAllUsers = () => SALES_APP_API.get("/user");
export const createUser = (payload) =>
  SALES_APP_API.post("/user/create", payload);
export const sendEmailVerificationLink = (email, loggedIn) => {
  return SALES_APP_API.post("/user/reset-password", { email, loggedIn });
};
// Orders
export const getAllOrders = (params) => SALES_APP_API.get("/order", { params });
export const getOrder = (orderId) => SALES_APP_API.get(`/order/${orderId}`);
export const notifyPriorityRequester = ({ email, orderId }) =>
  SALES_APP_API.post("/order/notify-priority", { email, orderId });
export const updateOrder = ({ orderId, dealer }) =>
  SALES_APP_API.patch(`/order/${orderId}`, { dealer });
export const sendOrderInvoice = (payload) =>
  SALES_APP_API.post("/order/order-invoice-pdf", payload);
export const sendOrderMarketplaceInvoice = (payload) =>
  SALES_APP_API.post("/order/marketplace-invoice-email", payload);
export const notifyDealerCancellation = (payload) =>
  SALES_APP_API.post("/order/notify-dealer-cancellation", payload);
export const notifyMarketplaceOrder = (payload) =>
  SALES_APP_API.post("/order/marketplace-notify", payload);
export const generatePDF = (payload) =>
  SALES_APP_API.post("/order/download-pdf-link", payload);
export const generateSignedURL = (payload) =>
  SALES_APP_API.get("/order/generate-download-link", {
    params: { path: payload }
  });
// Dealer
export const getAllDealers = () => SALES_APP_API.get("/dealer");
export const createDealer = (dealerDetails) =>
  SALES_APP_API.post("/dealer", { ...dealerDetails });
export const validatePriorityId = (priorityId) =>
  SALES_APP_API.get(`/dealer/validate-priority-id/${priorityId}`);
export const getDealer = (dealer, fields = []) =>
  SALES_APP_API.get(`/dealer/${dealer}`, {
    params: { fields: fields.join(",") }
  });
export const updateDealer = (dealer, fields) =>
  SALES_APP_API.patch(`/dealer/${dealer}`, { changes: { ...fields } });

export const updateRevenue = async (formData) => {
  const { data } = await SALES_APP_API.post("/dealer/update-revenue", formData);
  return data;
};
// Commercial
export const sendCustomerConditionReport = async (payload) => {
  const { data } = await SALES_APP_API.post(
    "/commercial/report-condition-email",
    payload
  );
  return data;
};
// Counter
export const getCounters = (fields = []) =>
  SALES_APP_API.get("/counter", { params: { fields: fields.join(",") } });
// Form
export const sendFormCsv = (payload) =>
  SALES_APP_API.post("/form/form-pdf-email", payload);
// Logs
export const getLogs = (payload) =>
  SALES_APP_API.get("/log", { params: payload });
// Payments
export const getPayments = (payload) =>
  SALES_APP_API.get("/payment", { params: payload });
// Visitor Logs
export const getAllBranches = async () =>
  await SALES_APP_API.get("/visitor/retrieveAllBranches");
export const getAllVisitors = async (payload) =>
  await SALES_APP_API.get("/visitor/retrieveAllVisitors", { params: payload });
export const createVisitor = async (payload) =>
  await SALES_APP_API.post("/visitor/createVisitor", payload);
export const signOutVisitor = async (payload) =>
  await SALES_APP_API.patch("/visitor/signOut", payload);
export const getAllVisitorsForToday = async () =>
  await SALES_APP_API.get("/visitor/retrieveVisitorsForToday");
// Service Request
export const getAllDealersUnprotected = async () =>
  await SALES_APP_API.get("/service-request/getAllDealers");
export const createServiceRequest = async (payload) =>
  await SALES_APP_API.post("/service-request/createServiceRequest", payload);
export const createServiceRequestPickUp = async (payload) =>
  await SALES_APP_API.post(
    "/service-request/createServiceRequestPickUp",
    payload
  );
export const uploadFileByAPI = async (filename, buffer) => {
  const response = await SALES_APP_API.post(
    "/service-request/uploadFile",
    buffer,
    {
      headers: {
        "Content-Type": "application/octet-stream",
        "X-Filename": filename
      }
    }
  );
  return response.data;
};
export const getAllServiceRequestPickUp = async (payload) =>
  await SALES_APP_API.get("/service-request/retrieveAllServiceRequestPickUp", {
    params: payload
  });
export const getAllServiceRequestDropOff = async (payload) =>
  await SALES_APP_API.get("/service-request/retrieveAllServiceRequestDropOff", {
    params: payload
  });
// Account Register
export const createAccountRegisterRequest = async (payload) =>
  await SALES_APP_API.post(
    "/account-register/createAccountRegisterRequest",
    payload
  );
export const getAllRegistrations = async (payload) =>
  await SALES_APP_API.get("/account-register/retrieveAllRegistrations", {
    params: payload
  });
export const updateAccountRegisterRequest = async (payload) =>
  await SALES_APP_API.post(
    "/account-register/updateAccountRegisterRequest",
    payload
  );
export const createUserFromRegistration = async (payload) =>
  await SALES_APP_API.post(
    "/account-register/createAccountRegisterUser",
    payload
  );

const config = {
  development: {
    dealers: "masterListDealers_dev",
    commercial: "commercial_customer_dev",
    marketplace: "orders",
    visitor: "visitor_logs_dev",
    user_logs: "user_logs_dev",
    account_register: "account_register_dev",
    users: "users_dev",
    notification: "notification_dev"
  },
  production: {
    dealers: "masterListDealers",
    commercial: "commercial_customers",
    marketplace: "orders",
    visitor: "visitor_logs",
    user_logs: "user_logs",
    account_register: "account_register",
    users: "users",
    notification: "notification"
  }
};

export default function getFirestoreCollectionConfig() {
  if (process.env.NODE_ENV === "production") {
    return config.production;
  }

  return config.development;
}

export default {
  // DEALER
  CREATE_DEALER: "CREATE DEALER",
  UPDATE_DEALER: "UPDATE DEALER",
  // ADMIN
  CREATE_USER: "CREATE USER",
  DELETE_REGISTRATION: "DELETE REGISTRATION",
  UPDATE_REGISTRATION_REQUEST: "UPDATE REGISTRATION REQUEST",
  CREATE_DEALER_USER: "CREATE DEALER USER",
  // AUTH
  CHANGE_PASSWORD: "CHANGE PASSWORD",
  REQUEST_RESET_PASSWORD: "REQUEST RESET PASSWORD",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  // COMMERCIAL
  CREATE_CUSTOMER: "CREATE CUSTOMER",
  UPDATE_CUSTOMER: "UPDATE CUSTOMER",
  DELETE_CUSTOMER: "DELETE CUSTOMER",
  SEND_CUSTOMER_REPORT_CONDITION: "SEND CUSTOMER REPORT CONDITION",
  // ORDER
  CHECK_SENT: "CHECK SENT",
  SEND_PRIORITY: "SEND PRIORITY",
  SEND_ORDER_EMAIL: "SEND ORDER EMAIL",
  SEND_ORDER_INVOICE: "SEND ORDER INVOICE",
  UPDATE_ORDER_FROM_FIRESTORE: "UPDATE ORDER FROM FIRESTORE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  SEND_EMAIL: "SEND_EMAIL",
  SEND_INVOICE: "SEND_INVOICE",
  EXPORT: "EXPORT",
  EXPORT_ALL: "EXPORT ALL",
  UPDATE_ORDER_FOLLOW_UP_TRACKING: "UPDATE ORDER FOLLOW UP TRACKING",
  CREATE_ORDER: "CREATE ORDER",
  //VISITOR
  SIGN_OUT_VISITOR: "SIGN OUT VISITOR",
  DELETE_VISITOR: "DELETE VISITOR",
  //REVENUE
  UPLOAD_REVENUE: "UPLOAD REVENUE",
  //POS
  POS_POST_ORDER: "POS POST ORDER",
  //NOTIFICATION
  PUBLISH_ANNOUNCEMENT: "PUBLISH ANNOUNCEMENT"
};

import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import * as Helpers from "@/store/helpers";
import { updateRevenue } from "@/common/salesAppApi";
import { getAuth } from "firebase/auth";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";
import { serverTimestamp } from "firebase/firestore";

export default {
  async [Actions.GET_ALL_REVENUE]({ commit }) {
    try {
      const revenue = await Helpers.getAllRevenue("revenue");
      commit(Mutations.SET_ALL_REVENUE, revenue);
    } catch (error) {
      commit(Mutations.SET_DEALER_ERROR, error);
    }
  },
  async [Actions.UPDATE_REVENUE](
    { commit, dispatch },
    { file, type, month, quarter, year }
  ) {
    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileText = event.target.result;
        try {
          const body = {
            csv: fileText,
            type: type,
            month: month,
            quarter: quarter,
            year: year
          };
          resolve(await updateRevenue(body));
        } catch (error) {
          commit(Mutations.SET_DEALER_ERROR, error);
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
    if (data.success) {
      dispatch(Actions.GET_ALL_REVENUE);
      const currentUser = getAuth()?.currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: currentUser.email,
        action: LOG_ACTIONS.UPLOAD_REVENUE,
        module: MODULE.REVENUE,
        target: JSON.stringify({
          type: type,
          month: month,
          quarter: quarter,
          year: year
        }),
        datePerformed: serverTimestamp()
      });
    }
    return data;
  }
};

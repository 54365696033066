import { collection, getDocs } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";

export const getAllRevenue = async (dbCollection) => {
  const revenue = await getDocs(collection(Firebase.db, `${dbCollection}`));
  return revenue.empty
    ? parseRevenueDoc(getDocs(collection(Firebase.db, `${dbCollection}`)))
    : parseRevenueDoc(revenue);
};
export const parseRevenueDoc = (snapshot) => {
  const revenue = [];
  snapshot.forEach((doc) => {
    revenue.push({
      ...doc.data()
    });
  });
  return revenue;
};

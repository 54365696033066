export default {
  getInitialValidation(state) {
    return state.initialValidation;
  },
  getRegistrations(state) {
    return state.registrations;
  },
  getRegistrationsCount(state) {
    return state.count;
  },
  registrationToDelete(state) {
    return state.toDelete;
  },
  registrationToEdit(state) {
    return state.toEdit;
  },
  registrationToRegister(state) {
    return state.toRegister;
  }
};

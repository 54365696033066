import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import {
  createServiceRequest,
  createServiceRequestPickUp,
  getAllServiceRequestPickUp,
  getAllServiceRequestDropOff
} from "@/common/salesAppApi";
import { getAllDealersUnprotected } from "@/common/salesAppApi";

export default {
  async [Actions.GET_ALL_DEALERS_UNPROTECTED]({ commit }) {
    commit(Mutations.SET_SERVICE_LOADING, true);
    try {
      const { data } = await getAllDealersUnprotected();
      commit(Mutations.SET_ALL_DEALERS, data.dealers);
    } catch (error) {
      // loading false
      commit(Mutations.SET_DEALER_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },
  [Actions.UPDATE_SELECTED_DEALER]({ commit }, dealer) {
    commit(Mutations.SET_SELECTED_DEALER, dealer);
  },
  async [Actions.CREATE_SERVICE_REQUEST]({ commit }, payload) {
    let data;
    try {
      data = await createServiceRequest(payload);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data;
  },
  async [Actions.CREATE_SERVICE_REQUEST_PICKUP]({ commit }, payload) {
    let data;
    try {
      data = await createServiceRequestPickUp(payload);
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
    }
    return data;
  },
  async [Actions.GET_ALL_SERVICEREQUESTS_PICKUP](
    { commit },
    { page = 1, filter, priorityId }
  ) {
    try {
      commit(Mutations.SET_SERVICE_LOADING, true);
      const {
        data: { serviceRequests, count }
      } = await getAllServiceRequestPickUp({ page, filter, priorityId });
      commit(Mutations.SET_ALL_SERVICEREQUESTS_PICKUP, serviceRequests);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT, count);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },
  async [Actions.GET_ALL_SERVICEREQUESTS_DROPOFF](
    { commit },
    { page = 1, filter }
  ) {
    try {
      commit(Mutations.SET_SERVICE_LOADING, true);
      const {
        data: { serviceRequests, count }
      } = await getAllServiceRequestDropOff({ page, filter });
      commit(Mutations.SET_ALL_SERVICEREQUESTS_DROPOFF, serviceRequests);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT_DROPOFF, count);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  }
};

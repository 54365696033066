import * as Mutations from "@/store/mutations.type";
export default {
  [Mutations.SET_ALL_REVENUE](state, revenue) {
    const last_month_revenue = revenue
      .filter((part) => part.type === "last_month")
      .reduce((acc, part) => acc.concat(part.data), []);

    const last_quarter_revenue = revenue
      .filter((part) => part.type === "last_quarter")
      .reduce((acc, part) => acc.concat(part.data), []);

    const yearly_revenue = revenue
      .filter((part) => part.type === "yearly")
      .reduce((acc, part) => acc.concat(part.data), []);

    if (last_month_revenue.length) {
      state.last_month.revenue = last_month_revenue;
      state.last_month.month = revenue.filter(
        (part) => part.type === "last_month"
      )[0].month;
      state.last_month.year = revenue.filter(
        (part) => part.type === "last_month"
      )[0].csvYear;
    }

    if (last_quarter_revenue.length) {
      state.last_quarter.revenue = last_quarter_revenue;
      state.last_quarter.quarter = revenue.filter(
        (part) => part.type === "last_quarter"
      )[0].quarter;
      state.last_quarter.year = revenue.filter(
        (part) => part.type === "last_quarter"
      )[0].csvYear;
    }

    if (yearly_revenue.length) {
      state.yearly.revenue = yearly_revenue;
      state.yearly.pickedYear = revenue.filter(
        (part) => part.type === "yearly"
      )[0].year;
      state.yearly.year = revenue.filter(
        (part) => part.type === "yearly"
      )[0].csvYear;
    }
  }
};
